<template>
  <section class="section py-3 py-lg-2 px-2 px-lg-4 img-technology" id="about">
    <div class="container">
      <div class="row py-3 py-lg-5">
        <div class="col-lg-3 col-md-5 px-3 order-md-first" data-aos="fade-zoom-in" data-aos-delay="50" data-aos-easing="ease-in-back">
          <img class="my-3 img-fluid" src="@/assets/images/img4.png" alt="development" />
        </div>
        <div class="col-lg-9 col-md-7 px-2" data-aos="zoom-in" data-aos-delay="100">
          <div class="h3 mb-2 marker">{{$strings.aboutSection.title}}</div>
         
         <p class="text-secondary mt-4" >Somos uma agência digital full service e software house que desenvolve sites, aplicativos e gerencia marcas, oferecendo soluções digitais personalizadas para gerar resultados impactantes. Nossa missão é simplificar e amplificar a presença online dos clientes, com foco em soluções eficientes e sob medida.</p>
         
         <p class="text-secondary mt-4" >Na Solluten Digital, nos comprometemos com o sucesso dos clientes, criando estratégias inteligentes que atraem novos clientes e fortalecem o relacionamento com os atuais, sempre visando crescimento mensurável e sustentável.</p>
         
          </div>       
      </div>
    </div>   
  </section>
</template>

<!--<p class="text-secondary mt-4" >Somos uma Agência Digital full service criativa e software house focada em gerar resultados impactantes para nossos clientes e parceiros. Desenvolvemos seu site ou aplicativo, gerenciamos sua marca, landing pages, blogs e portais, utilizando ferramentas avançadas e reconhecidas do mercado para criar soluções personalizadas que realmente funcionam.</p>
    <p class="text-secondary mt-3" >Nossa missão é clara: simplificar e amplificar a presença digital de nossos clientes. Acreditamos que, para crescer e se destacar na internet, sua empresa precisa de soluções digitais eficientes e sob medida. E é exatamente isso que oferecemos.</p>
      <p class="text-secondary mt-3" >Na Solluten Digital, não nos limitamos a entregar projetos; nos comprometemos com o sucesso de cada cliente. Nosso objetivo é impulsionar o crescimento do seu negócio, criando estratégias inteligentes que não apenas atraem novos clientes, mas também fortalecem o relacionamento com sua base atual. Cada solução é desenvolvida com foco em resultados mensuráveis, que elevam sua marca e aumentam seu faturamento.</p>
      <p class="text-secondary mt-3" >Acreditamos que o sucesso de nossos clientes é o reflexo do nosso sucesso. Por isso, estamos sempre em busca de inovação e excelência, garantindo que cada projeto contribua diretamente para o crescimento sustentável e para a expansão digital da sua empresa.</p>
    -->
<script>
export default {
  name: 'AboutSection',
  data() {
    return {
      technologies: [
        'android', 'aspnet',  'csharp', 'vuejs', 'react', 'python', 'nodejs', 'azure', 'angular', 'aws', 'flutter', 'figma'
      ]      
      //'elixir','postgrees', 'rubyonrails', 'phoenix', 'django', 'ios', 'ruby', 
    };
  }
};
</script>

<style scoped>
</style>
