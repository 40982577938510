<template>
  <section class="section py-2 px-2 px-lg-4 img-technology" id="technology">
    <div class="container">
      <div class="text-center mb-4">
        <h2 class="marker marker-center text-section">{{ $strings.technologySection.title }}</h2>
      </div>
      <div class="text-center">
        <!--<p class="mx-auto mb-3 text-secondary " style="max-width:1024px">
          {{ $strings.technologySection.description }}
        </p>-->
      </div>
      <div class="text-center"  >
        <div class="row row-cols-auto justify-content-center g-3" >
          <div class="col p-1" v-for="(tech, index) in technologies" :key="index"  :data-aos="'fade-up'" :data-aos-delay="(200 + (index * 150))">
            <img class="rounded" :src="require(`@/assets/images/tecnology/${tech}.svg`)" height="64px" :alt="`${tech} logo`">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TechnologiesSection',
  data() {
    return {
      technologies: [
        'android', 'aspnet',  'csharp', 'vuejs', 'react', 'python', 'nodejs', 'azure', 'angular', 'aws', 'flutter', 'figma'
      ]      
      //'elixir','postgrees', 'rubyonrails', 'phoenix', 'django', 'ios', 'ruby', 
    };
  }
};
</script>

<style scoped>
.img-technology img {
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  /* transition: box-shadow 0.3s ease-in-out, box-shadow 0.3s ease-in-out; */
}

.img-technology img:hover {
  filter: grayscale(0%);
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);*/
  /* transform: translateY(-5px);  */
}

</style>
