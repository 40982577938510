<template>
  <div id="app">
    <header>
      <nav id="header-nav" ref="navbar" class="navbar navbar-expand-lg navbar-light  bg-light">
          <div class="container">
            <a class="navbar-brand" href="">
              <img width="160" class="d-inline-block align-text-top" src="@/assets/logo.png" alt="logotipo da Solluten Digital"/>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto me-2" style="font-weight: 500;">
                <li class="nav-item"><a class="nav-link nav-anim" href="#"> {{ $strings.strHome }}</a></li>               
                <li class="nav-item"><a class="nav-link nav-anim" href="#services"> {{ $strings.strServices }}</a></li>
                <li class="nav-item"><a class="nav-link nav-anim" href="#portfolio"> {{ $strings.strPortfolio }}</a></li> 
                 <li class="nav-item"><a class="nav-link nav-anim" href="#about"> {{ $strings.strAbout }}</a></li>
                <!-- <li class="nav-item"><a class="nav-link nav-anim" href="#team">Time</a></li> -->                
                <!-- <li class="nav-item"><a class="nav-link nav-anim" href="#portfolio">Playgound</a></li> -->
              </ul>
              <a class="btn btn-primary" href="#footer"> {{ $strings.strTalkToUse }}</a>
            </div>
          </div>
      </nav>
      <div id="scrolltop" ref="scrollTop"></div>
    </header>
    <header>
      <section class="cover bg-light">
        <div class="container pt-5 pb-5">
          <div class="row">
            <div class="col-lg-6">
              <div class="mt-5 pt-lg-5">
                <h1 class="intro-title"  style="font-weight: 700; font-size: 2.1em; color: #003778" data-aos="fade-left" data-aos-delay="50">{{ basePhrase }}</h1>
                <h1 data-aos="fade-left" data-aos-delay="50" class="intro-title text-section" style="font-weight: 700; font-size: 2.1em; color: #003778" >para <span >{{ currentEnding }}</span> </h1>
                
                <p class="lead fw-normal mt-2" data-aos="fade-up" data-aos-delay="100">{{ $strings.strTecnologiaInovacao }}</p>
                <div class="mt-3" data-aos="fade-up" data-aos-delay="200">
                  <a class="btn btn-primary shadow-sm mt-1 me-2" href="#contact">
                    {{ $strings.strTalkToUse }} <i class="fas fa-arrow-right ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 p-3 pe-lg-0" data-aos="fade-left" data-aos-delay="100"><img class="pt-2 img-fluid" src="@/assets/images/img5.png" alt="Ilustração de pessoas construindo soluções"/></div>
          </div>
        </div>
      </section>
      <div class="wave-bg"></div>
    </header>

    
    <!-- Como podemos ajudar seu negócio -->
    <ServicesSection />

    <!-- NÚMEROS DOS PROJETOS  bg-secondary -->
    <div class="section text-light py-3 py-lg-5 px-2 px-lg-4 bg-main" id="stats">
      <div class="container">
        <div class="row px-4">
          <div class="col-md-3 col-sm-6">
            <div class="d-flex py-2"><i class="mr-3 fas fa-clipboard-check fa-4x fa-fw"></i>
              <div class="ms-1">
                <div class="h2 mb-1 purecounter" data-purecounter-start="0" data-purecounter-end="30" data-purecounter-duration="2">+80</div>
                <div class="h6">{{ $strings.strProjects }} </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="d-flex py-2"><i class="mr-3 fas fa-smile fa-4x fa-fw"></i>
              <div class="ms-1">
                <div class="h2 mb-1 purecounter" data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="1">+120</div>
                <div class="h6">{{ $strings.strClients }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="d-flex py-2"><i class="mr-3 fas fa-clock fa-4x fa-fw"></i>
              <div class="ms-1">
                <div class="h2 mb-1 purecounter" data-purecounter-start="0" data-purecounter-end="200" data-purecounter-duration="3">+200</div>
                <div class="h6">{{ $strings.strHoursSuport }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="d-flex py-2"><i class="mr-3 fas fa-users fa-4x fa-fw"></i>
              <div class="ms-1">
                <div class="h2 mb-1 purecounter" data-purecounter-start="0" data-purecounter-end="280" data-purecounter-duration="4">+200.000</div>
                <div class="h6">{{ $strings.strImpactedUsers }}</div>                
              </div>
            </div>
          </div>
        </div>        
      </div>      
      
    </div>


    <!-- Portfólio -->
    <ArtsSection />


    <!-- Transforme sua marca -->
    <TansformBrandingSection />

     <!-- FAZEMOS SEU PROJETO -->
    <div class="section p-3 py-lg-5" id="stats">
      <div class="container text-center">
        <h1 style="color: #003778"><b v-html="$strings.strEleveteYourBrand"> </b></h1>
        <!-- <h1><b> Não aceitamos qualquer desafio. <strong class="text-dark">Abraçamos o seu projeto</strong> como se fosse nosso. </b></h1> -->
      </div>
    </div>

   
    <!-- Logotipo e identidade -->
    <BrandingSection />
   
  
   
 
    <!-- DESENVOLVIMENTO -->
    <!-- <ProcessDevelopmentSection /> -->


   

    

    <!-- PLAYGROUND -->
    <!--<PlaygroundSection />-->
    
    <!-- PORTFOLIO -->
    <!--<PortfolioSection />-->

    <!-- QUEM SOMOS -->
    <AboutSection />

    <!-- TECNOLOGIA QUE USAMOS -->
    <TechnologiesSection />

     <!-- TECNOLOGIA QUE USAMOS -->
    <ContactSection />

    <!--<ChatBot /> --> 

    <PrivacyCookies />
    

    <!-- FOOTER -->
    <footer class="py-5 px-2 footer-custom" id="footer" style="background: #003778;">
      <div class="container">
        <div class="row"> 
          <div class="col-lg-4 col-md-12 mb-3">
            <div class="mb-3">
              <div class="h4">
                <img width="160" class="d-inline-block align-text-top" src="@/assets/logotipo_fundo_escuro.png" alt="Logotipo da Solluten Digital para fundo escuro"/>
              </div>
              <div class="social-nav">
                <nav role="navigation">
                  <ul class="nav text-light">
                    <li class="nav-item"><a class="nav-link" target="_blank" href="https://www.facebook.com/profile.php?id=61564141033090" title="Facebook"><i class="fab fa-facebook"></i><span class="menu-title sr-only">Facebook</span></a></li>
                    <li class="nav-item"><a class="nav-link" target="_blank" href="https://www.instagram.com/solluten" title="Instagram"><i class="fab fa-instagram"></i><span class="menu-title sr-only">Instagram</span></a></li>
                    <li class="nav-item"><a class="nav-link" target="_blank" href="https://www.linkedin.com/company/solluten" title="LinkedIn"><i class="fab fa-linkedin"></i><span class="menu-title sr-only">LinkedIn</span></a></li>
                  </ul>
                </nav>
              </div>
            </div>
           
          </div>
          <div class="col-lg-4 col-md-6 mb-3">
            <div class="text-uppercase mb-2">{{$strings.strServices}}</div>
            <div class="nav flex-column">
              <a class="nav-link-footer px-0" href="#services">{{$strings.servicesSection.services[0].title}}</a>
              <a class="nav-link-footer px-0" href="#services">{{$strings.servicesSection.services[1].title}}</a>
              <a class="nav-link-footer px-0" href="#services">{{$strings.servicesSection.services[2].title}}</a>
              <a class="nav-link-footer px-0" href="#services">{{$strings.servicesSection.services[3].title}}</a>
              <a class="nav-link-footer px-0" href="#services">{{$strings.servicesSection.services[4].title}}</a>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 mb-3">
            <div class="text-uppercase mb-2">{{ $strings.strWhoWeAre }}</div>
            <div class="nav flex-column">
            <a class="nav-link-footer px-0" href="#about">{{ $strings.strAbout }}</a>
            <a class="nav-link-footer px-0" href="#technology">{{ $strings.technologySection.title }}</a>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 mb-3">
            <div class="text-uppercase mb-2">Contato</div>
            <div class="text-small">
              <address>
                Rio de Janeiro - RJ
              </address>
              <div class="mb-1"><strong>Tel.:</strong><br/>
                <a class="nav-link-footer" title="Brundo Brandoli" href="https://wa.me/+19028029984" target="_blank" rel="noopener noreferrer"> <i class="fab fa-whatsapp"></i> +1 (902) 802-9984 </a><br/>
                <a class="nav-link-footer" title="Cleidimar Viana" href="https://wa.me/+5511948700140" target="_blank" rel="noopener noreferrer"><i class="fab fa-whatsapp"></i>  +55 (11) 94870-0140 </a></div>
              <div class="mb-1"><strong>Email:</strong><br/>contato@solluten.com</div>
            </div>
          </div>
        </div>
      </div>  
        
    </footer>
    <div style="height: 50px; color: azure; background-color: #002957; display: flex; align-items: center; justify-content: center;">
        <div class="container text-center">
            <div class="text-small">
                <span class="mb-1" v-html="$strings.strCopyright"></span>  
                <a class="nav-link-footer px-2" href="#!">{{ $strings.strTerms }}</a> 
                <a class="nav-link-footer px-2" href="#!">{{ $strings.strPrivacity }}</a>
            </div>
        </div>
      </div>  
  </div>
</template>

<script>
// import '@/assets/themes.css'; 
import ServicesSection from '@/components/ServicesSection';
import TechnologiesSection from '@/components/TechnologiesSection';

import TansformBrandingSection from '@/components/TansformBrandingSection';
import AboutSection from '@/components/AboutSection';
import ContactSection from '@/components/ContactSection';
import PrivacyCookies from '@/components/PrivacyCookies';
//import PortfolioSection from '@/components/PortfolioSection';
//import PlaygroundSection from '@/components/PlaygroundSection';
//import ProcessDevelopmentSection from '@/components/ProcessDevelopmentSection';

import BrandingSection from '@/components/BrandingSection';
import ArtsSection from './components/ArtsSection.vue';
//import ChatBot from './components/ChatBot.vue';
export default {
  name: 'App',
  components: {
    ServicesSection,
    TechnologiesSection, 
    TansformBrandingSection,    
    BrandingSection,
    ArtsSection,
    AboutSection,
    ContactSection,
    PrivacyCookies
    //ChatBot
    //PortfolioSection,
    //PlaygroundSection,
    //ProcessDevelopmentSection,
  },
  data() {
    return {
      basePhrase: "Soluções criativas",
      endings: ["vender mais", "impulsionar seu negócio", "ganhar visibilidade", "ampliar possibilidades"],
      currentEnding: "",
      currentIndex: 0,
      typingSpeed: 80, 
      deletingSpeed: 40, 
      delayBeforeNext: 1600, 
    };
  },
  mounted() {
    const navbar = this.$refs.navbar;
    const body = document.body;
    //const scrollTop = this.$refs.scrollTop;

    const handleScroll = () => {
      if (window.scrollY > 0) {
        navbar.classList.add('fixed-top', 'shadow-sm', 'bg-white');
        body.style.paddingTop = `${navbar.offsetHeight}px`;
        // scrollTop.style.visibility = 'visible';
        // scrollTop.style.opacity = 1;
      } else {
        navbar.classList.remove('fixed-top', 'shadow-sm', 'bg-white');
        body.style.paddingTop = '0px';
        // scrollTop.style.visibility = 'hidden';
        // scrollTop.style.opacity = 0;
      }
    };

    window.addEventListener('scroll', handleScroll);   

    this.loopEndings();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    loopEndings() {
      this.typeEnding();
    },
    typeEnding() {
      const currentEndingText = this.endings[this.currentIndex];
      let charIndex = 0;
      const typingInterval = setInterval(() => {
        if (charIndex < currentEndingText.length) {
          this.currentEnding += currentEndingText[charIndex];
          charIndex++;
        } else {
          clearInterval(typingInterval);
          setTimeout(() => this.deleteEnding(), this.delayBeforeNext);
        }
      }, this.typingSpeed);
    },
    deleteEnding() {
      const deletingInterval = setInterval(() => {
        if (this.currentEnding.length > 0) {
          this.currentEnding = this.currentEnding.slice(0, -1);
        } else {
          clearInterval(deletingInterval);
          this.currentIndex = (this.currentIndex + 1) % this.endings.length;
          setTimeout(() => this.typeEnding(), this.typingSpeed);
        }
      }, this.deletingSpeed);
    }
  }//
}
</script>

<style>
/* 
footer {
  background-color: #151515;
  color: white;
  text-align: center;
  padding: 1rem 0;
  width: 100%;
  bottom: 0;
} */
.card-game{
  border: none;
  height: 80px;
}
.background {
  background-color: #fff;
  /* background-image: url('https://bootstrapmade.com/demo/templates/QuickStart/assets/img/hero-bg-light.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;  */
}

.wave-bg {
    display: block;
    height: 220px;
    width: 100%;
    /* bmin-width: 600px; */
    transform-origin: top;
    -webkit-animation: animateWave 2000ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
    animation: animateWave 2000ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
    background-image: url(@/assets/images/wave-bg.svg);
    background-position: center;
    background-repeat: no-repeat;
}


@-webkit-keyframes animateWave {
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes animateWave {
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.bg-main{
  background: #003778;
}
#header-nav {
    transition: all 0.5s ease;
}
.bg-white{
  background-color: #fff;
}
.text-small{
  font-size: 0.8em;
}

.home-dev i{
  color: #304D71;
}

.mt-n4 {
    margin-top: -2rem !important;
}
.icon-services i{
  font-size: 2.2em;
  color: #003778;
}



/* Container com borda giratória */
/* .icon-services {
    position: relative;
    overflow: hidden;
    padding: 1rem;
    transition: transform 0.3s ease;
}

.icon-services::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-radius: 50%; 
    transition: border-color 2s ease;
    box-sizing: border-box;
    z-index: 1;
    pointer-events: none;
    animation: border-spin 2s infinite linear; 
}

@keyframes border-spin {
    0% {
        border-color: transparent transparent transparent #1B3659;
        transform: rotate(0deg);
    }
    75% {
        border-color: transparent transparent transparent #7c7667;
        transform: rotate(90deg);
    }
    50% {
        border-color: transparent transparent transparent #FFCB37; 
        transform: rotate(180deg);
    }
    75% {
        border-color: transparent transparent transparent #7c7667; 
        transform: rotate(270deg);
    }
    100% {
        border-color: transparent transparent transparent #1B3659; 
        transform: rotate(360deg);
    }
}
 */
 .marker {
  position: relative;
  display: inline;
  width: auto;
}

.marker-center {
  padding-right: 0;
}

.marker:after {
  content: "";
  width: 125px;
  height: 30px;
  position: absolute;
  bottom: -25px;
  right: -30px;
  background-image: url(@/assets/images/marker.svg);
  background-repeat: no-repeat;
}

.marker-center:after {
  bottom: -30px;
  left: 50%;
  margin-left: -60px;
}

@media (max-width: 575.98px) {
  .marker {
    padding-right: 0;
  }
  .marker:after {
    bottom: -30px;
    left: 50%;
    margin-left: -60px;
  }
}

.nav-anim {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    text-decoration: none;
}
.nav-anim::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    display: block;
    margin-top: 4px;
    background: #FCB132; 
    transition: width 0.4s ease;
}

.nav-anim:hover::after {
    width: 80%;
}
.text-decoration-none a{
  text-decoration: none; 
}

.footer-custom{
  color: #fff;
}

.footer-custom .nav-link{
  color: #c5c5c5;
  font-size: 1.5em;
}
.nav-link-footer{
  color: #c5c5c5;
  text-decoration: none;
  margin-bottom: 8px;
}
.nav-link-footer:hover, .footer-custom .nav-link:hover{
  color: #FFCB37;
  
}
</style>
