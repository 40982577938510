<template>
    <div class="section bg-light py-3 py-lg-5 px-2 px-lg-4" id="portfolio">
      <div class="container">
        <div class="text-center mb-5">
          <h2 class="marker marker-center text-section">{{ $strings.artsSection.title }}</h2>
        </div>
       <!-- <div class="text-center">
          <p class="mx-auto mb-3 text-secondary " style="max-width:1024px">
            {{ $strings.artsSection.description }}
          </p>
        </div>
      -->
        <div class="row py-3" >
          <div class="portfolio-grid">
            <div class="portfolio-item" v-for="index in imageCount" :key="index">
              <img :src="require(`@/assets/images/arts/${index}.jpg`)" alt="Portfolio Item 1">
            </div>
           
          </div>        
        </div>
      </div>
    </div>
  </template>
  
  <script>  
  export default {
    name: 'ArtsSection',
    components: {
    },
    data() {
      return {
        imageCount: 24 
      };
    }
  };
  </script>
  
  <style scoped>

.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 12px;
    padding: 12px;
}

/* Estilo dos itens do grid */
.portfolio-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Imagem dentro do item */
.portfolio-item img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    display: block;
    transition: opacity 0.3s ease;
}

/* Efeito de hover */
.portfolio-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Efeito de hover na imagem */
.portfolio-item:hover img {
    opacity: 0.8;
    filter: grayscale(0);
}
  </style>
  
  