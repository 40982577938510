import { createApp } from 'vue'
import App from './App.vue'
import { analytics } from './firebase';

console.log(analytics.app.name);
//analytics.logEvent('notification_received');

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'

// Import the SCSS theme file
import '@/assets/themes/themes1/theme1.scss'
import strings from '@/assets/locales/pt-BR/strings.json'

import AOS from 'aos'
import 'aos/dist/aos.css'

// Create the Vue app
const app = createApp(App)

app.config.productionTip = false

// Pass strings to the app
app.config.globalProperties.$strings = strings

// Initialize AOS
AOS.init({
  // Customize the options here if needed
  duration: 1200, // Duration of animations in milliseconds
  easing: 'ease-in-out', // Easing function
  once: true, // Whether animations should happen only once
})

// Mount the app
app.mount('#app')
