<template>
  <div class="col-md-4 text-center mb-3 service-card" :data-aos="'fade-up'" :data-aos-delay="delay" >
    <div class="bg-light rounded-circle d-inline-block p-4 icon-services">
      <i :class="icon"></i>
    </div>
    <div class="bg-light rounded p-3 mt-n4 ">
      <div class="h5 mt-3">{{ title }}</div>
      <p class="text-small text-secondary ">{{ description }}</p>
      <!-- <a class="btn btn-link btn-sm text-decoration-none text-dark" href="#!">
        Ler mais<i class="fas fa-arrow-right ms-1"></i>
      </a>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    description: String,
    delay: String
  }
};
</script>

<style scoped>
/* Adicione estilos específicos para este componente aqui */
</style>
