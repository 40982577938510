<template>
  <!-- Modal de Política de Cookies -->
  <div v-if="showCookieModal" class="cookie-modal">
    <div class="cookie-content">
      <h3>Política de Cookies</h3>
      <p>
        Utilizamos cookies para melhorar sua experiência de navegação, analisar como você interage com nosso site e personalizar conteúdo e anúncios. Para saber mais sobre como usamos cookies, consulte nossa <a href="/politica-de-cookies" target="_blank">Política de Cookies</a>.
      </p>
      <div class="cookie-options">
       
        <button @click="showPreferences" class="btn btn-main">Configurações</button>
        <button @click="acceptCookies" class="btn btn-accept">Aceitar Todos</button>
      </div>
    </div>
  </div>

  <!-- Modal de Preferências de Cookies -->
  <div v-if="showPreferencesModal" class="preferences-modal">
    <div class="preferences-content">
      <h3>Preferências de Cookies</h3>
      <p>Selecione quais tipos de cookies deseja permitir:</p>
      <div class="cookie-checkbox">
        <label>
          <input type="checkbox" v-model="essentialCookies" disabled checked />
          Cookies Essenciais (sempre ativados)
        </label>
      </div>
      <div class="cookie-checkbox">
        <label>
          <input type="checkbox" v-model="analyticsCookies" />
          Cookies de Análise
        </label>
      </div>
      <div class="cookie-checkbox">
        <label>
          <input type="checkbox" v-model="advertisingCookies" />
          Cookies de Publicidade
        </label>
      </div>
      <div class="cookie-buttons">
        <button  @click="savePreferences" class="btn btn-main">Salvar Preferências</button>
        <button @click="acceptAll" class="btn btn-accept">Aceitar Todos</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCookieModal: true,
      showPreferencesModal: false,
      essentialCookies: true, // Sempre ativados
      analyticsCookies: false,
      advertisingCookies: false,
    };
  },
  methods: {
    acceptCookies() {
      this.setCookiePreferences(true, true, true);
      this.showCookieModal = false;
    },
    showPreferences() {
      this.showPreferencesModal = true;
      this.showCookieModal = false;
    },
    savePreferences() {
      this.setCookiePreferences(
        this.essentialCookies,
        this.analyticsCookies,
        this.advertisingCookies
      );
      this.showPreferencesModal = false;
    },
    acceptAll() {
      this.analyticsCookies = true;
      this.advertisingCookies = true;
      this.savePreferences();
    },
    setCookiePreferences(essential, analytics, advertising) {
      document.cookie = `essentialCookies=${essential}; path=/`;
      document.cookie = `analyticsCookies=${analytics}; path=/`;
      document.cookie = `advertisingCookies=${advertising}; path=/`;
    },
    checkCookieConsent() {
      const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
        const [key, value] = cookie.split("=");
        acc[key] = value === "true";
        return acc;
      }, {});

      if (cookies.analyticsCookies || cookies.advertisingCookies) {
        this.showCookieModal = false;
      }
    },
  },
  mounted() {
    this.checkCookieConsent();
  },
};
</script>

<style scoped>
.cookie-modal, .preferences-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  z-index: 9999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cookie-content, .preferences-content {
  max-width: 1300px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.cookie-options, .cookie-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px; 
}

.cookie-checkbox {
  text-align: left;
  margin-bottom: 10px;
}


a {
  color: #003778;
  text-decoration: underline;
}

a:hover {
  color: #00204d;
}

.btn-accept {
  background-color: #003778;
  color: white;
}

.btn-accept:hover {
  background-color: #00204d;
  color: white;
}

</style>
