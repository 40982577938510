<template>
  <section class="section py-3 py-lg-5 px-2 px-lg-4 img-technology" id="contact">
    <div class="container">
      <div class="text-center mb-5">
          <h2 class="marker marker-center">Fale com a gente</h2>
      </div>
      <div class="row">
            <div class="col-md-6" data-aos="zoom-in" data-aos-delay="100">
                <div class="bg-light my-2 p-3 pt-2">
                    <form action="https://formspree.io/seu@email.com" method="POST">
                        <div class="form-group my-2">
                            <label for="nome" class="form-label fw-bolder">Nome</label>
                            <input class="form-control" type="text" id="nome" name="nome" required>
                        </div>
                        <div class="form-group my-2">
                            <label for="email" class="form-label fw-bolder">E-mail</label>
                            <input class="form-control" type="email" id="email" name="_replyto" required>
                        </div>
                        <div class="form-group my-2">
                            <label for="mensagem" class="form-label fw-bolder">Mensagem</label>
                            <textarea class="form-control" style="resize: none;" id="mensagem" name="mensagem" rows="4" required></textarea>
                        </div>
                        <button class="btn btn-primary mt-2" type="submit">Enviar</button>
                    </form>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-left" data-aos-delay="300">
                <div class="mt-3 px-2">
                    <p>Tire dúvidas e descubra o plano ideal para transformar a comunicação da sua empresa! Não deixe pra depois a revolução que já começou.</p>
                </div>
                <div class="mt-5 px-2">
                    <div class="row">
                        <div class="col-sm-2">
                            <div class="pb-1">E-mail:</div>
                        </div>
                        <div class="col-sm-10">
                            <div class="pb-1 fw-bolder">contato@solluten.com</div>
                        </div>
                        <div class="col-sm-2">
                            <div class="pb-1">Telefone:</div>
                        </div>
                        <div class="col-sm-10">
                            <div class="pb-1 text-decoration-none"><a title="Brundo Brandoli" href="https://wa.me/+19028029984" target="_blank" rel="noopener noreferrer"> <i class="fab fa-whatsapp"></i> +1 (902) 802-9984 </a><br/><a title="Cleidimar Viana" href="https://wa.me/+5511948700140" target="_blank" rel="noopener noreferrer"><i class="fab fa-whatsapp"></i>  +55 (11) 94870-0140 </a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
  </section>
</template>

<script>
export default {
  name: 'ContactSection',
  data() {
    return {
      
    };
  }
};
</script>

<style scoped>

</style>
