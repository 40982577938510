<template>
  <div class="section px-2 mt-5 px-lg-4" id="transform">
    <div class="container-narrow">
      <div class="text-center mb-4">
        <h2 class="marker marker-center text-section" v-html="$strings.transformBrandingSection.title"></h2>
      </div>

    </div>
    <div class="container">
      <div class="row py-3 py-lg-5 d-flex align-items-center">
        <div class="col-lg-7 col-md-7 px-2" data-aos="zoom-in" data-aos-delay="100">
          <div class="h3 mb-2 marker text-section" v-html="$strings.transformBrandingSection.subtitle1"></div>
          <p class="text-secondary mt-4" v-html="$strings.transformBrandingSection.paragraph1"></p>
        </div>
        <div class="col-lg-5 col-md-5 px-3 order-md-last" data-aos="fade-zoom-in" data-aos-delay="50" data-aos-easing="ease-in-back">
          <img class="my-3 img-fluid" src="@/assets/images/img3.png" alt="development" />
        </div>        
      </div>
    </div>
    <div class="container">
      <div class="row py-3 py-lg-2 d-flex align-items-center">
        <div class="col-lg-5 col-md-5 px-3 order-md-first" data-aos="fade-zoom-in" data-aos-delay="50" data-aos-easing="ease-in-back">
          <img class="my-3 img-fluid" src="@/assets/images/img1.png" alt="development" />
        </div>
        <div class="col-lg-7 col-md-7 px-2" data-aos="zoom-in" data-aos-delay="100">
          <div class="h3 mb-2 marker text-section" v-html="$strings.transformBrandingSection.subtitle2"></div>          
          <p class="text-secondary mt-4" v-html="$strings.transformBrandingSection.paragraph2"></p>
        </div>       
      </div>
    </div>
  </div>    

</template>

<script>

export default {
  name: 'TansformBrandingSection',
  components: {
    
  }
};
</script>
  
<style scoped>

</style>
  
  