<template>
    <div class="section bg-light py-3 py-lg-5 px-2 px-lg-4" id="services">
      <div class="container">
        <div class="text-center mb-5">
          <h2 class="marker marker-center text-section">{{ $strings.brandingSection.title }}</h2>
        </div>
      <!--<div class="text-center">
          <p class="text-secondary mx-auto mb-5" style="max-width: 1024px">
            {{ $strings.brandingSection.description }}
          </p>
        </div>-->
        <div class="row py-3" >
          <ServiceBrandingCard v-for="(service, index) in $strings.brandingSection.services" :key="index"
            :icon="service.icon"
            :title="service.title"
            :description="service.description"
            :delay="service.delay"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ServiceBrandingCard from './ServiceBrandingCard.vue';
  
  export default {
    name: 'BrandingSection',
    components: {
      ServiceBrandingCard
    }
  };
  </script>
  
  <style scoped>
  /* Adicione estilos específicos para este componente aqui */
  </style>
  
  