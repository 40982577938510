<template>
  <div class="col-md-3 mb-5 " :data-aos="'fade-up'" :data-aos-delay="delay">
    <div class="bg-white rounded p-3 mt-n4 branding-card">
      <!-- Título e ícone lado a lado -->
      <div class="d-flex align-items-center">
        <div class="rounded-circle p-1 icon-services me-3">
          <i :class="icon"></i>
        </div>
        <div class="h6">{{ title }}</div>
      </div>
      <!-- Descrição abaixo do ícone e título -->
      <p class="text-small mt-3 text-secondary ">{{ description }}</p>
      <!--<a class="btn btn-link btn-sm text-decoration-none text-dark" href="#!">
        Ler mais<i class="fas fa-arrow-right ms-1"></i>
      </a> -->
    </div>
  </div>
</template>


<script>
export default {
  props: {
    icon: String,
    title: String,
    description: String,
    delay: String
  }
};
</script>

<style scoped>

.branding-card {
  cursor: pointer;
  transition: transform 0.2s ease, opacity 0.3s ease!important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)!important;
  
}

.branding-card:hover {
  transform: translateY(-2px)!important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2)!important;
}

.branding-card:hover .icon-services i, .branding-card:hover .h6 {
  color: #003778dd!important; 
  transition: color 0.3s ease!important; 
}
</style>
